/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../../components/seo';

import { PAGES } from '../../constants/Languages';

// Components
import News from '../../components/Common/Articles/News';
import layoutContext from '../../components/layoutContext';
import { PAGES_LINKS } from '../../constants/pagesLinks';


const Categories = ({ data }) => {
  useContext(layoutContext).setCult('ru');
  const { stocks } = data;
  const { edges } = stocks;

  const news = edges.reduce((pages, article) => {
    if (article.node.frontmatter.image) {
      const {
        node: {
          frontmatter: {
            headLine, path, lang, category, date,
            image: { childImageSharp: { resize: { src } } }
          }
        }
      } = article;
      if (lang === 'ru' && category === 'news') {
        pages.push({
          headLine,
          path,
          date,
          image: src
        });
      }
    }
    return pages;
  }, []);


  return (
    <>
      <Seo
        title={PAGES.news['ru']}
        path={PAGES_LINKS['news']}
      />
      <News posts={news} title={PAGES.news['ru']} />
    </>
  );
};

Categories.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
};

export default Categories;

export const pageQuery = graphql`
    {
      stocks: allMarkdownRemark(
        sort: {fields: [frontmatter___date], order: DESC},
        filter: {fileAbsolutePath: {regex: "/content/news/"}}
      ) {
        totalCount
        edges {
          node {
            frontmatter {
              headLine
              path
              lang
              category
              date
              image {
                childImageSharp {
                  resize(width: 350, height: 220) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
`;
