import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import layoutContext from '../../layoutContext';
import { MONTHS } from '../../../constants/Languages';

import './Article.scss';


const NewsArticle = ({ headLine, path, date, image }) => {

  const { cult } = useContext(layoutContext);

  const year = date.slice(0, 4);
  const number = date.slice(8, 10);
  const month = MONTHS[cult][+date.slice(5, 7) - 1];
  const fotmatDate = `${number} ${month} ${year}`;


  return (
    <Link to={path} className='article-post'>
      <div className='read-more'>Читать далее »</div>
      <img
        className='article-image' src={image}
        alt={headLine}
      />
      <span className='article-date'>{fotmatDate}</span>
      <h3 className='post-description-title'>{headLine}</h3>
    </Link>
  );
};

export default NewsArticle;

NewsArticle.propTypes = {
  headLine: PropTypes.string,
  path: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
};


